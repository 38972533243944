import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { TabIcon } from './TabIcon'

export interface Props {
  directions: { [key: string]: any }
  title?: string
}

export const Directions = memo(function Directions({
  directions,
  title,
}: Props) {
  if (!directions) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {directions ? (
        <FadeInUp>
          <Tabs>
            <TabList>
              {directions.map((item: any, index: number) => (
                <Tab key={index}>
                  <TabIcon icon={item.icon} />
                  {item.label}
                </Tab>
              ))}
            </TabList>

            {directions.map((item: any, index: number) => (
              <TabPanel key={index}>
                {item.label ? <TabTitle>{item.label}</TabTitle> : null}
                {item.description ? (
                  <TabText
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                ) : null}
              </TabPanel>
            ))}
          </Tabs>
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6rem 10.972vw 8.125rem;

  .react-tabs {
    display: flex;
    max-width: 65.139vw;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: auto;
    .react-tabs__tab-list {
      display: flex;
      flex-direction: column;
      width: 14.583vw;
      margin: 0;
      padding: 0;
    }
    .react-tabs__tab {
      display: flex;
      align-items: center;
      height: 5rem;
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.variants.neutralLight3};
      color: ${({ theme }) => theme.colors.variants.neutralLight2};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.75rem;
      font-weight: 800;
      letter-spacing: 0.075rem;
      line-height: 0.9375rem;
      padding: 0 1.875rem;
      position: relative;
      text-transform: uppercase;
      transition: 0.2s ease-out;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 10px solid
          ${({ theme }) => theme.colors.variants.primaryLight1};
        border-bottom: 12px solid transparent;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        transition: 0.3s ease-in-out;
        z-index: -1;
      }
      &:focus {
        box-shadow: none;
      }
      &:last-of-type {
        border-bottom: 0;
      }
      &:hover {
        background: ${rgba(theme.colors.variants.neutralLight3, 0.36)};
      }
      &.react-tabs__tab--selected {
        background: ${({ theme }) => theme.colors.variants.primaryLight1};
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        &:after {
          opacity: 1;
          transform: translate(0.625rem, -50%);
          z-index: 0;
        }

        svg {
          fill: ${({ theme }) => theme.colors.variants.neutralLight4};
        }
      }
    }
    .react-tabs__tab-panel {
      display: none;
      width: calc(100% - 14.583vw);
      padding: 4rem 6.667vw 5.625rem;
      &.react-tabs__tab-panel--selected {
        display: block;
        animation: fadeInPanel 0.6s;
      }
    }

    @keyframes fadeInPanel {
      from {
        opacity: 0;
        transform: translateY(2.25rem);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 1199px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;

    .react-tabs {
      max-width: none;
      .react-tabs__tab-list {
        width: 12.5rem;
      }
    }
  }

  @media (max-width: 1023px) {
    padding-top: 3.75rem;
    padding-bottom: 5.625rem;

    .react-tabs {
      display: block;
      max-width: none;
      .react-tabs__tab-list {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .react-tabs__tab {
        width: 50%;
        height: 2rem;
        &:after {
          display: none;
        }
        &:last-of-type {
          border-bottom: 1px solid
            ${({ theme }) => theme.colors.variants.neutralLight3};
        }
      }
      .react-tabs__tab-panel {
        width: 100%;
        padding: 3rem 1.9375rem;
      }
    }
  }
`

const TabTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 1.75rem;
`

const TabText = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 1.25rem;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.25rem;
  margin-bottom: 3.625rem;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    margin-bottom: 1.875rem;
  }
`
